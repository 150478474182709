<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    max-width="600"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit Aging</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <v-select
          v-model="formData.country_id_from"
          label="Country From"
          item-text="text"
          item-value="index"
          :items="serverData.countries"
          clearable
          outlined
          dense
          required
          :error-messages="country_id_fromErrors"
          @input="$v.formData.country_id_from.$touch()"
          @blur="$v.formData.country_id_from.$touch()"
        ></v-select>
        <v-select
          v-model="formData.country_id_to"
          label="Country To"
          item-text="text"
          item-value="index"
          :items="serverData.countries"
          clearable
          outlined
          dense
          :error-messages="country_id_toErrors"
          @input="$v.formData.country_id_to.$touch()"
          @blur="$v.formData.country_id_to.$touch()"
        ></v-select>
        <v-text-field
          v-model="formData.delivery_time"
          label="Aging Days"
          clearable
          type="Number"
          outlined
          dense
          :error-messages="deliveryTimeErrors"
          @input="$v.formData.delivery_time.$touch()"
          @blur="$v.formData.delivery_time.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="formData.attempt_time"
          label="Attempt Time"
          clearable
          type="Number"
          outlined
          dense
          :error-messages="attemptTimeErrors"
          @input="$v.formData.attempt_time.$touch()"
          @blur="$v.formData.attempt_time.$touch()"
        ></v-text-field>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      country_id_from: { required },
      country_id_to: { required },
      delivery_time: { required },
      attempt_time: { required },
    },
  },
  props: ["refresher", "pageLoader", "parents", "roles", "customers"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      country_id_from: null,
      country_id_to: null,
      delivery_time: null,
      attempt_time: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/fulfillment/agings/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = { ...this.serverData.aging };
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      this.$v.$touch();
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/fulfillment/agings/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Record data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        country_id_from: null,
        country_id_to: null,
        delivery_time: null,
        attempt_time: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    country_id_fromErrors() {
      return this.handleFormValidation("country_id_from", this);
    },
    country_id_toErrors() {
      return this.handleFormValidation("country_id_to", this);
    },
    deliveryTimeErrors() {
      return this.handleFormValidation("delivery_time", this);
    },
    attemptTimeErrors() {
      return this.handleFormValidation("attempt_time", this);
    },
  },
};
</script>
