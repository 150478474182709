<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Aging</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <v-select
          v-model="formData.country_id_from"
          label="Country From"
          item-text="text"
          item-value="index"
          :items="serverData.countries"
          clearable
          outlined
          dense
          required
          :error-messages="country_id_fromErrors"
          @input="$v.formData.country_id_from.$touch()"
          @blur="$v.formData.country_id_from.$touch()"
        ></v-select>
        <v-select
          v-model="formData.country_id_to"
          label="Country To"
          item-text="text"
          item-value="index"
          :items="serverData.countries"
          clearable
          outlined
          dense
          :error-messages="country_id_toErrors"
          @input="$v.formData.country_id_to.$touch()"
          @blur="$v.formData.country_id_to.$touch()"
        ></v-select>
        <v-text-field
          v-model="formData.delivery_time"
          label="Aging Days"
          clearable
          type="Number"
          outlined
          dense
          :error-messages="deliveryTimeErrors"
          @input="$v.formData.delivery_time.$touch()"
          @blur="$v.formData.delivery_time.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="formData.attempt_time"
          label="Attempt Time"
          clearable
          type="Number"
          outlined
          dense
          :error-messages="attemptTimeErrors"
          @input="$v.formData.attempt_time.$touch()"
          @blur="$v.formData.attempt_time.$touch()"
        ></v-text-field>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      country_id_from: { required },
      country_id_to: { required },
      delivery_time: { required },
      attempt_time: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      country_id_from: null,
      country_id_to: null,
      delivery_time: null,
      attempt_time: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/fulfillment/agings/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/fulfillment/agings/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Record has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        country_id_from: null,
        country_id_to: null,
        value: null,
      };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    country_id_fromErrors() {
      return this.handleFormValidation("country_id_from", this);
    },
    country_id_toErrors() {
      return this.handleFormValidation("country_id_to", this);
    },
    deliveryTimeErrors() {
      return this.handleFormValidation("delivery_time", this);
    },
    attemptTimeErrors() {
      return this.handleFormValidation("attempt_time", this);
    },
  },
};
</script>
